exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jp-about-tsx": () => import("./../../../src/pages/jp/about.tsx" /* webpackChunkName: "component---src-pages-jp-about-tsx" */),
  "component---src-pages-jp-contact-tsx": () => import("./../../../src/pages/jp/contact.tsx" /* webpackChunkName: "component---src-pages-jp-contact-tsx" */),
  "component---src-pages-jp-index-tsx": () => import("./../../../src/pages/jp/index.tsx" /* webpackChunkName: "component---src-pages-jp-index-tsx" */),
  "component---src-pages-jp-portfolio-index-tsx": () => import("./../../../src/pages/jp/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-jp-portfolio-index-tsx" */),
  "component---src-pages-jp-portfolio-sisterhoodie-tsx": () => import("./../../../src/pages/jp/portfolio/sisterhoodie.tsx" /* webpackChunkName: "component---src-pages-jp-portfolio-sisterhoodie-tsx" */),
  "component---src-pages-jp-services-tsx": () => import("./../../../src/pages/jp/services.tsx" /* webpackChunkName: "component---src-pages-jp-services-tsx" */),
  "component---src-pages-jp-team-tsx": () => import("./../../../src/pages/jp/team.tsx" /* webpackChunkName: "component---src-pages-jp-team-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfolio-sisterhoodie-tsx": () => import("./../../../src/pages/portfolio/sisterhoodie.tsx" /* webpackChunkName: "component---src-pages-portfolio-sisterhoodie-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-templates-portfolio-page-tsx": () => import("./../../../src/templates/portfolio-page.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

