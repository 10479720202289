export const COLORS = {
	light: {
		tofuDarkGrey: "#EBEBEB",
		tofuLightGrey: "#F6F6F6",
		tofuLightWhite: "#2A2A2A",
		tofuDarkWhite: "#2A2A2A", // NOT TMP
	},
	dark: {
		tofuDarkGrey: "#1A1A1A",
		tofuLightGrey: "#2A2A2A",
		tofuLightWhite: "#f6f6f6",
		tofuDarkWhite: "#ebebeb",
	},
}
