import './src/styles/global.css'
import React from "react"
import { ThemeProvider } from "./src/components/ThemeProvider/ThemeProvider";

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider>
            {element}
        </ThemeProvider>
    )
}