import React, { useContext } from "react"
import { COLORS } from "../../constants/colors"

export type Theme = "light" | "dark"

interface ThemeContextT {
	colorMode: Theme
	setColorMode: (value: Theme) => void
}

export const ThemeContext = React.createContext<ThemeContextT | undefined>(
	undefined
)

export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({
	children,
}) => {
	const [colorMode, rawSetColorMode] = React.useState<Theme>("dark")

	React.useEffect(() => {
		const root = window.document.documentElement
		const cssSays = root.style.getPropertyValue("--initial-color-mode")
		const initialColorValue = cssSays === "light" ? "light" : "dark" //localStorage.getItem("color-mode") || "dark"
		rawSetColorMode(initialColorValue)
	}, [])

	const setColorMode = (newValue: Theme) => {
		const root = window.document.documentElement
		// 1. Update React color-mode state
		rawSetColorMode(newValue)
		// 2. Update localStorage
		localStorage.setItem("color-mode", newValue)
		// 3. Update each color
		root.style.setProperty(
			"--color-tofuDarkGrey",
			newValue === "light"
				? COLORS.light.tofuDarkGrey
				: COLORS.dark.tofuDarkGrey
		)
		root.style.setProperty(
			"--color-tofuLightGrey",
			newValue === "light"
				? COLORS.light.tofuLightGrey
				: COLORS.dark.tofuLightGrey
		)
		root.style.setProperty(
			"--color-tofuLightWhite",
			newValue === "light"
				? COLORS.light.tofuLightWhite
				: COLORS.dark.tofuLightWhite
		)
		root.style.setProperty(
			"--color-tofuDarkWhite",
			newValue === "light"
				? COLORS.light.tofuDarkWhite
				: COLORS.dark.tofuDarkWhite
		)
	}

	return (
		<ThemeContext.Provider value={{ colorMode, setColorMode }}>
			{children}
		</ThemeContext.Provider>
	)
}

export const useTheme = () => useContext(ThemeContext)!
